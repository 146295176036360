// Here you can add other styles
  
  @media only screen and (min-width: 768px) {
    .label {
        padding-right: 10px;
    }
  }
  @media only screen and (max-width: 768px) {
    .labelDate {
      float: left;

    }
    .label{
        padding-right: 10px;
    }
  }